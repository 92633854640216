<template>
    <div>
        <st-modal
            id="modal-gdpr"
            hide-header-delimiter
            hide-footer-delimiter
            size="lg"
            hideable
            ref="st-gdpr-modal"
            customClass="form-modal"
        >
            <template #header>
                <div class="text-center w-100">
                    <i class="fas fa-info-circle text-primary font-size-h1"></i>
                </div>
            </template>
            <template #body>
                <p class="font-size-h6">
                    {{ $t('SET_PROFILE_DATA.GDPR.CONDITIONS') }}
                </p>
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.CLOSE') }}
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>

<script>
export default {
    name: 'GdprModal',
    methods: {
        hide() {
            this.$refs['st-gdpr-modal'].hide();
        },
        show() {
            this.$refs['st-gdpr-modal'].show();
        },
    }
};
</script>
