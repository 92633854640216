<template>
    <div>
        <form class="form" novalidate="novalidate" id="st-person-form">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.personal_numeric_code.label }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="model[fields.personal_numeric_code.name]"
                                :ref="fields.personal_numeric_code.name"
                                :name="fields.personal_numeric_code.name"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.last_name.label }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="model[fields.last_name.name]"
                                :ref="fields.last_name.name"
                                :name="fields.last_name.name"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.first_name.label }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="model[fields.first_name.name]"
                                :ref="fields.first_name.name"
                                :name="fields.first_name.name"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.county_id.label }} *
                        </label>
                        <div label="">
                            <st-autocomplete
                                v-model="model[fields.county_id.name]"
                                :options="fields.county_id.options"
                                :ref="fields.county_id.name"
                                :name="fields.county_id.name"
                                :fields="fields"
                                :model="model"
                                :formValidation="fv"
                            >
                            </st-autocomplete>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.locality_id.label }} *
                        </label>
                        <div label="">
                            <st-autocomplete
                                v-model="model[fields.locality_id.name]"
                                :options="fields.locality_id.options"
                                :ref="fields.locality_id.name"
                                :name="fields.locality_id.name"
                                :disabled="!fields.locality_id.options.length"
                                :fields="fields"
                                :model="model"
                                :formValidation="fv"
                            >
                            </st-autocomplete>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm form-group">
                            <label>
                                {{ fields.village_id.label }}
                            </label>
                            <div label="">
                                <st-autocomplete
                                    v-model="model[fields.village_id.name]"
                                    :options="fields.village_id.options"
                                    :ref="fields.village_id.name"
                                    :name="fields.village_id.name"
                                    :disabled="!fields.village_id.options.length"
                                    :fields="fields"
                                    :model="model"
                                    :formValidation="fv"
                                >
                                </st-autocomplete>
                            </div>
                        </div>
                        <div class="col-sm">
                            <div class="form-group">
                                <label>
                                    {{ fields.sector.label }}
                                </label>
                                <div>
                                    <st-input-text
                                        :ref="fields.sector.name"
                                        :name="fields.sector.name"
                                        v-model="model[fields.sector.name]"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>
                            {{ fields.street.label }} *
                        </label>
                        <div>
                            <st-input-text
                                :ref="fields.street.name"
                                :name="fields.street.name"
                                v-model="model[fields.street.name]"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm">
                            <div class="form-group">
                                <label>
                                    {{ fields.street_number.label }} *
                                </label>
                                <st-input-text
                                    :ref="fields.street_number.name"
                                    :name="fields.street_number.name"
                                    v-model="model[fields.street_number.name]"
                                    allow-only-alphanumeric
                                />
                            </div>
                        </div>
                        <div class="col-sm">
                            <label>
                                {{ fields.staircase.label }}
                            </label>
                            <st-input-text
                                :ref="fields.staircase.name"
                                :name="fields.staircase.name"
                                v-model="model[fields.staircase.name]"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm">
                             <label>
                                {{ fields.block.label }}
                            </label>
                            <st-input-text
                                :ref="fields.block.name"
                                :name="fields.block.name"
                                v-model="model[fields.block.name]"
                            />
                        </div>
                        <div class="col-sm">
                             <label>
                                    {{ fields.floor.label }}
                            </label>
                            <div>
                                <st-input-text
                                    :ref="fields.floor.name"
                                    :name="fields.floor.name"
                                    v-model="model[fields.floor.name]"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-sm">
                            <div class="form-group">
                                <label>
                                    {{ fields.apartment.label }}
                                </label>
                                <div>
                                    <st-input-text
                                        :ref="fields.apartment.name"
                                        :name="fields.apartment.name"
                                        v-model="model[fields.apartment.name]"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm">
                             <div class="form-group">
                                <label>
                                    {{ fields.postal_code.label }}
                                </label>
                                <div>
                                    <st-input-text
                                        :ref="fields.postal_code.name"
                                        :name="fields.postal_code.name"
                                        v-model="model[fields.postal_code.name]"
                                        set-validation
                                        :max-length="6"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>
                            {{ fields.email.label }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="model[fields.email.name]"
                                :ref="fields.email.name"
                                :name="fields.email.name"
                                :disabled="true"
                            />
                        </div>
                    </div>
                    <div class="form-group">
                        <label>
                            {{ fields.phone_number.label }} *
                        </label>
                        <div label="">
                            <st-input-text
                                v-model="model[fields.phone_number.name]"
                                :ref="fields.phone_number.name"
                                :name="fields.phone_number.name"
                                phone-validation
                            />
                        </div>
                    </div>
                </div>
            </div>
            <id-card-details
                :model="model"
                :fields="fields"
                :formValidation="fv"
                :idCardFields="idCardFields"
            />
        </form>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProfileModel } from "@/modules/profile/profile-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = ProfileModel;

const formSchema = new FormSchema([
    fields.id,
    fields.user_type_name,
    fields.personal_numeric_code,
    fields.first_name,
    fields.last_name,
    fields.county_id,
    fields.locality_id,
    fields.village_id,
    fields.sector,
    fields.street,
    fields.street_number,
    fields.postal_code,
    fields.staircase,
    fields.block,
    fields.floor,
    fields.apartment,
    fields.email,
    fields.phone_number,
    fields.id_card_serial,
    fields.id_card_number,
    fields.id_card_expiration_date,
    fields.id_card_issuer,
]);

export default {
    props: {
        user: Object,
    },
    components: {
        IdCardDetails: () => import("./id-card-section"),
    },
    data() {
        return {
            model: {
                sector: '',
                apartment: '',
                block: '',
                staircase: '',
                floor: '',
                user_type_name: "citizen",
            },
            fields,
            rules: formSchema.rules(),
            fv: null,
            idCardFields: ['id_card_serial', 'id_card_number', 'id_card_expiration_date', 'id_card_issuer']
        };
    },
    computed: {
        ...mapGetters({
            defaultCountyId: "shared/defaultCountyId",
        }),
    },
    created() {
        const initialValues = formSchema.initialValues(this.user);
        this.model = { ...initialValues, ...this.model};
        this.model.county_id = this.defaultCountyId;

        this.idCardFields.forEach((field) => {
            this.fields[field].required = true;
        })
    },
    mounted() {
        this.fv = createFormValidation("st-person-form", this.rules);
    },
};
</script>
