<template>
    <div class="mb-16 mt-2">
        <div class="mb-10">
            <p class="st-dark-large">{{ $t('SET_PROFILE_DATA.UPDATE_PROFILE_MODAL.HEADER') }}</p>
            <p>{{ $t('SET_PROFILE_DATA.UPDATE_PROFILE_MODAL.TEXT') }}</p>
        </div>

        <div class="py-10 w-100 profile-settings mb-4" @click="goToProfile()">
            <div class="d-flex justify-content-between profile-settings-wrapper">
                <div>
                    <div class="item-name">{{ $t('SET_PROFILE_DATA.UPDATE_PROFILE_MODAL.GO_TO_PROFILE') }}</div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <i class="fas fa-chevron-right"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'update-profile-modal-page',
    data() {
        return {
            modalShow: true
        };
    },
    computed: {
        logoImage() {
            return `${process.env.BASE_URL}media/logos/logo-GU-CJCJ.png`;
        },
    },
    methods: {
        goToProfile() {
            this.$router.push({
                name: 'profile',
            });
        },
    },
};
</script>

<style scoped lang="scss" src="@/assets/sass/pages/home/welcome-modal.scss"></style>
