<template>
    <div>
        <div class="mb-4">
            <p class="st-dark-large">{{ $t('SET_PROFILE_DATA.WELCOME_MODAL.HEADER.TOP_P') }}</p>
            <p class="font-size-lg mt-4">{{ $t('SET_PROFILE_DATA.WELCOME_MODAL.HEADER.BOTTOM_P_PERSON') }}</p>
        </div>
        <!-- TODO: Remove v-if after go live or if is requested -->
        <div class="d-flex justify-content-start mt-2" v-if="false">
            <b-form-group>
                <b-form-radio-group
                    v-model="selected"
                    :options="options"
                    name="radio-options"
                ></b-form-radio-group>
            </b-form-group>
        </div>
        <div>
            <div v-if="selected ==='person'">
                <PersonForm ref="userForm" :user="currentUser"/>
            </div>
            <div v-if="selected ==='foreigner'">
                <ForeignerForm ref="userForm" :user="currentUser"/>
            </div>
            <div class="pb-lg-0 pb-5 ">
                <div class="my-4 ">
                    <b-form-checkbox
                        v-model="gdpr_consent"
                        ref="gdpr_consent"
                        id="gdpr_consent"
                        size="lg"
                    >
                        <a href="#" class="text-primary pb-4 font-size-sm" @click.prevent="showModal">
                            {{ $t('SET_PROFILE_DATA.GDPR.AGREE_CONDITION') }}
                        </a>
                    </b-form-checkbox>
                </div>
                <div class="d-flex justify-content-between">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="changeProfileType"
                    >
                        {{ $t('SET_PROFILE_DATA.FORM_BUTTONS.CHANGE_TYPE') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        :spinner="loading['auth/updateProfile']"
                        customClass="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="onSubmit"
                        :disabled="!gdpr_consent"
                    >
                        {{ $t('SET_PROFILE_DATA.FORM_BUTTONS.SAVE') }}
                    </st-button>
                </div>
            </div>
        </div>
        <gdpr-modal ref="gdpr-modal"/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PersonForm from '@/modules/home/components/person-form';
import ForeignerForm from '@/modules/home/components/foreigner-form';
import GdprModal from '@/modules/home/components/gdpr-modal';

export default {
    name:'IndividualModal',
    components: {
        PersonForm,
        ForeignerForm,
        GdprModal
    },
    data() {
        return {
            selected: 'person',
            options: [
                { text: this.$t('SET_PROFILE_DATA.CITIZEN.ROMANIAN'), value: 'person' },
                { text: this.$t('SET_PROFILE_DATA.CITIZEN.FOREIGNER'), value: 'foreigner' },
            ],
            gdpr_consent: false,
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            loading: 'shared/loading',
        }),
    },
    methods: {
        ...mapActions({
            updateProfile: 'auth/updateProfile',
        }),
        async onSubmit() {
            const form = this.$refs.userForm;
            const validateForm = await form.fv.validate();
            if (validateForm === 'Valid') {
                let payload = {
                    ...form.model
                };

                if (this.selected === 'person') {
                    payload = {
                        ...payload,
                        locality: form.fields.locality_id.options.find((el) => el.value === form.model.locality_id)?.text || '',
                        county: form.fields.county_id.options.find((el) => el.value === form.model.county_id)?.text || '',
                        village: form.fields?.village_id.options.find((el) => el.value === form.model.village_id)?.text || '',
                    }
                }
                this.onUpdateProfile(payload);
            }
        },
        onUpdateProfile(formData) {
            // Email is not send in payload because it cannot be updated
            const { email, ...payload } = formData;
            this.updateProfile(payload)
            .then((user) => {
                this.$bvModal.hide('user-profile-modal');

                const fullName = `${user.first_name} ${user.last_name}`;
                this.$alert({
                    title: `${this.$t('SET_PROFILE_DATA.SUCCESS_MODAL.TITLE', [fullName])}`,
                    type: 'success',
                    text: this.$t('SET_PROFILE_DATA.SUCCESS_MODAL.TEXT'),
                    confirmButtonText: this.$t('SET_PROFILE_DATA.SUCCESS_MODAL.CONFIRM_BUTTON'),
                    callback: () => {
                        this.$router.go();
                    },
                });
            })
        },
        changeProfileType() {
            this.$emit('selectModal', 'welcome');
        },
        showModal() {
            this.$refs['gdpr-modal'].show();
        }
    }
  }
</script>
