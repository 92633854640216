import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import IdField from '@/shared/fields/id-field';
import EmailField from '@/shared/fields/email-field';
import EnumField from '@/shared/fields/enum-field';

import { LocalityField } from '@/shared/common-fields/locality-field';
import { CountyField } from '@/shared/common-fields/county-field';
import { VillageField } from '@/shared/common-fields/village-field';
import { CountryField } from '@/shared/common-fields/country-field';
import { idCard } from '@/shared/utils/id-card';

const label = (name) => i18n.t(`PROFILE.FIELDS.${name.toUpperCase()}`);
const placeholder = (name) => i18n.t(`PROFILE.PLACEHOLDERS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`PROFILE.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    user_type_name: new StringField('user_type_name', label('user_type_name'), {}),
    personal_numeric_code: new StringField('personal_numeric_code', label('personal_numeric_code'), { personalNumericCode: true }),
    first_name: new StringField('first_name', label('first_name'), { required: true, alphabeticalSpecialChars: true, max: 50 }),
    last_name: new StringField('last_name', label('last_name'), { required: true, alphabeticalSpecialChars: true, max: 50 }),
    email: new EmailField('email', label('email'), { required: true }),
    phone_number: new StringField('phone_number', label('phone'), { phoneNumber: true }),
    county_id: CountyField.relationToOne(
        'county_id',
        label('county'),
        { autocompleteRequired: true, firstLetterFilter: true },
        { placeholder: placeholder('county')},
        [],
        { asyncData: true }
    ),
    locality_id: LocalityField.relationToOne(
        'locality_id',
        label('locality'),
        { autocompleteRequired: true, firstLetterFilter: true },
        { placeholder: placeholder('locality')},
        [{
            key: 'parent_id',
            name: 'county_id'
        }],
        { asyncData: true }
    ),
    village_id: VillageField.relationToOne(
        'village_id',
        label('village'),
        { autocompleteRequired: true, enabled: false, firstLetterFilter: true},
        { placeholder: placeholder('village')},
        [{
            key: 'parent_id',
            name: 'locality_id'
        }],
        { asyncData: true }
    ),
    sector: new StringField('sector', label('sector')),
    street: new StringField('street', label('street'), { required: true }),
    street_number: new StringField('street_number', label('street_number'), { required: true }),
    postal_code: new StringField('postal_code', label('postal_code'), { required: false }),
    staircase: new StringField('staircase', label('staircase')),
    block: new StringField('block', label('block')),
    floor: new StringField('floor', label('floor')),
    apartment: new StringField('apartment', label('apartment')),
    id_card_serial: new StringField(
        'id_card_serial',
        label('id_card_serial'),
        {
            regexp: /^[a-zA-Z]{2}$/,
            required: true
        },
    ),
    id_card_number: new StringField(
        'id_card_number',
        label('id_card_number'),
        {
            regexp: /^\d{6,7}$/,
            required: true
        },
    ),
    id_card_expiration_date: new StringField(
        'id_card_expiration_date',
        label('id_card_expiration_date'),
        {
            minDate: idCard.expirationDate,
            required: true
        },
    ),
    id_card_issuer: new StringField('id_card_issuer', label('id_card_issuer'), { required: true }),

    //foreigner
    passport_number: new StringField('passport_number', label('passport_number'), { required: true }),
    country: CountryField.relationToOne(
        'country',
        label('country'),
        { autocompleteRequired: true, enabled: false, firstLetterFilter: true },
        { placeholder: placeholder('country')},
    ),
    city: new StringField('city', label('city'), { required: true }),
    address: new StringField('address', label('address'), { required: true }),

    //legal
    license: new StringField('license', label('license')),
    unique_registration_code: new StringField('unique_registration_code', label('unique_registration_code'), { uniqueRegistrationCode: true }),
    registration_number: new StringField('registration_number', label('registration_number')),
    company_name: new StringField('company_name', label('company_name'), { required: true }),
    iban: new StringField('iban', label('iban'), { required: true, iban: true }),
    bank: new StringField('bank', label('bank'), { required: true }),

    //staff
    identification_number: new StringField('identification_number', label('identification_number'), { required: true }),
    function: new StringField('functions', label('functions'), {}),
    department: new StringField('departments', label('departments'), {}),
    organisation: new StringField('organisation', label('organisation'), {}),
    status: new EnumField('status', label('status'), [
        { value: 'active', text: enumLabel('status', 'active') },
        { value: 'inactive', text: enumLabel('status', 'inactive') }
    ], {}, { placeholder: placeholder('status') }),
};

export class ProfileModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
